import React from "react"
import { generateId } from "../../../shared/helpers"

export const BackgroundCircles = props => {
  const gradientId = generateId()

  return (
    <svg
      width="899"
      height="868"
      viewBox="0 0 899 868"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="441"
        cy="419"
        rx="336"
        ry="336"
        transform="rotate(-180 441 419)"
        fill={`url(#${gradientId})`}
      />
      <circle
        cx="441"
        cy="418"
        r="395.25"
        transform="rotate(-180 441 418)"
        stroke="#F6EDFA"
        strokeWidth="1"
      />
      <path
        d="M105.75 418C105.75 232.847 255.846 82.75 441 82.75C626.153 82.75 776.25 232.847 776.25 418C776.25 603.154 626.153 753.25 441 753.25C255.846 753.25 105.75 603.153 105.75 418Z"
        stroke="#F1DEFA"
        strokeWidth="1"
      />
      <ellipse cx="98" cy="222" rx="9.99995" ry="10" fill="#BC027F" />
      <ellipse cx="276.235" cy="124" rx="6.00001" ry="6" fill="#05F7F4" />
      <ellipse cx="774" cy="203" rx="6.00026" ry="6" fill="#159BF3" />
      <ellipse cx="256.999" cy="698" rx="5.99947" ry="6" fill="#F67300" />
      <ellipse cx="692.2" cy="636.956" rx="14.0001" ry="14" fill="#DBF0FF" />
      <ellipse cx="8.00018" cy="604" rx="8.00018" ry="8" fill="#E899CE" />
      <ellipse
        cx="884.872"
        cy="410.873"
        rx="13.8725"
        ry="13.8727"
        fill="#79CD75"
      />
      <defs>
        <radialGradient
          id={gradientId}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(441 419) rotate(90) scale(336)"
        >
          <stop offset="0.239583" stopColor="#F1DEFA" />
          <stop offset="0.869792" stopColor="#FCFAFF" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
      </defs>
    </svg>
  )
}
