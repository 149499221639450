import React from "react"
import { Heading, Spacer, LinkButton } from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"
import { contentPositionerCss } from "../../../shared/styles"
import { HeaderHeight } from "../../../shared/constants/layout"
import { BackgroundCircles } from "./background-circles"
import { normalizeContent } from "../../../CustomPageLayout"

const PICTURE_HEIGHT = `42rem`
const PICTURE_MOBILE_HEIGHT = `23.75rem`
const MAX_BLOCK_WIDTH = `56.25rem`

const rootCss = theme => [contentPositionerCss({ theme })]

const headerCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  alignItems: "center",
  justifyContent: "center",
  minHeight: PICTURE_MOBILE_HEIGHT,

  [theme.mediaQueries.tablet]: {
    justifyContent: `center`,
    minHeight: PICTURE_HEIGHT,
    maxWidth: MAX_BLOCK_WIDTH,
    margin: `-${HeaderHeight} auto 0`,
  },

  [theme.mediaQueries.desktop]: {
    marginTop: `calc((${HeaderHeight} / 2 - .25rem) * -1)`,
  },
})

const headingCss = theme => ({
  fontSize: theme.fontSizes[11],
  fontWeight: theme.fontWeights.extraBold,
  letterSpacing: theme.letterSpacings.tight,
  color: theme.colors.purple[60],
  lineHeight: 1.1,
  ".futura-loading &": {
    letterSpacing: `1px`,
    lineHeight: `1.15`,
    fontSize: `52px`,
    fontWeight: 900,
    [theme.mediaQueries.tablet]: {
      fontWeight: theme.fontWeights.extraBold,
      letterSpacing: theme.letterSpacings.tight,
      lineHeight: 1.1,
      fontSize: theme.fontSizes[13],
    },
    [theme.mediaQueries.desktop]: {
      fontWeight: theme.fontWeights.extraBold,
      letterSpacing: theme.letterSpacings.tight,
      lineHeight: 1.1,
      fontSize: theme.fontSizes[16],
    },
  },

  span: {
    display: "block",
    color: theme.colors.black,
  },

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[13],
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[16],
  },
})

const ctasCss = theme => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  position: "relative",

  [theme.mediaQueries.tablet]: {
    maxWidth: `72rem`,
    flexDirection: "row",
    justifyContent: "space-between",
    margin: `-60px auto 0`,
  },
})

const ctaBlockCss = theme => ({
  textAlign: "center",
  marginTop: theme.space[10],
  maxWidth: "24rem",
  marginLeft: `auto`,
  marginRight: `auto`,

  [theme.mediaQueries.tablet]: {
    width: "33.3333333%",
    display: "flex",
    flexDirection: "column",
    marginTop: "unset",
    padding: `0 ${theme.space[4]}`,
  },
})

const subtitleCss = theme => ({
  marginTop: theme.space[5],
  p: { margin: 0 },

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[5],
    marginTop: theme.space[10],
  },
})

const primaryButtonCss = theme => ({
  borderRadius: theme.radii[3],

  [theme.mediaQueries.tablet]: {
    marginTop: "auto",
    alignSelf: "center",
  },
})

const skyButtonCss = theme => ({
  ...primaryButtonCss(theme),
  background: theme.colors.blue[80],
  border: `1 px solid ${theme.colors.blue[90]}`,

  "&:hover": {
    border: `1 px solid ${theme.colors.blue[90]}`,
    background: theme.colors.blue[90],
  },
})

const magentaButtonCss = theme => ({
  ...primaryButtonCss(theme),
  background: theme.colors.magenta[50],
  border: `1 px solid ${theme.colors.magenta[90]}`,

  "&:hover": {
    border: `1 px solid ${theme.colors.magenta[90]}`,
    background: theme.colors.magenta[60],
  },
})

const ctaTitleCss = theme => ({
  fontWeight: theme.fontWeights.extraBold,
  letterSpacing: theme.letterSpacings.tight,
  fontSize: theme.fontSizes[8],

  [theme.mediaQueries.tablet]: {
    fontSize: theme.fontSizes[7],
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[8],
  },
})

const subtitlePrimaryCss = theme => ({
  // as long as we use Futura PT for headlines, with Inter we want `extraBold`
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[6],
  color: theme.colors.purple[60],
})

const subtitleSkyCss = theme => ({
  // as long as we use Futura PT for headlines, with Inter we want `extraBold`
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[6],
  color: theme.colors.blue[50],
})

const subtitleMagentaCss = theme => ({
  // as long as we use Futura PT for headlines, with Inter we want `extraBold`
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[6],
  color: theme.colors.magenta[50],
})

const variantCssByComponentName = {
  PageHeaderCtaPrimary: subtitlePrimaryCss,
  PageHeaderCtaSky: subtitleSkyCss,
  PageHeaderCtaMagenta: subtitleMagentaCss,
}

const buttonCssByComponentName = {
  PageHeaderCtaPrimary: primaryButtonCss,
  PageHeaderCtaSky: skyButtonCss,
  PageHeaderCtaMagenta: magentaButtonCss,
}

const ctaContentCss = theme => ({
  fontSize: theme.fontSizes[2],
})

const backgroundCircleCss = theme => ({
  position: "absolute",
  width: "120%",

  [theme.mediaQueries.tablet]: {
    width: "100%",
  },
})

const CtaBlock = ({ Title, Product, Content, Action, ...props }) => {
  return (
    <div css={ctaBlockCss} {...props}>
      {Title}
      {Product}
      <Spacer size={5} />
      <div>{Content}</div>
      {Action}
    </div>
  )
}

export const PageHeader = ({ data = {} }) => {
  const content = normalizeContent(data.content)

  const heading = content.find(item => item.componentName === "Heading")

  const catchphrase = content.find(item => item.componentName === "Markdown")

  const ctas = content
    .filter(
      item =>
        item.componentName === "PageHeaderCtaSky" ||
        item.componentName === "PageHeaderCtaMagenta" ||
        item.componentName === "PageHeaderCtaPrimary"
    )
    .map(rawCta => {
      const rawFields = rawCta.data.content
      const title = rawFields.find(field => field.name.includes("Title"))
      const subtitle = rawFields.find(field => field.name.includes("Subtitle"))
      const content = rawFields.find(field => field.name.includes("Content"))
      const link = rawFields.find(field => field.name.includes("Link"))

      return {
        title: title?.text?.text,
        subtitle: subtitle?.text?.text,
        content: content.body.childMarkdownRemark.html,
        link: link.href,
        linkText: link.anchorText,
        variant: rawCta.componentName,
      }
    })
  return (
    <div css={rootCss}>
      <header css={headerCss}>
        <BackgroundCircles css={backgroundCircleCss} />

        <div css={{ zIndex: 1, textAlign: "center" }}>
          <Heading
            id="page-header-title"
            css={headingCss}
            dangerouslySetInnerHTML={{ __html: heading?.data?.text?.text }}
          />

          <div
            id="page-subtitle"
            css={subtitleCss}
            dangerouslySetInnerHTML={{
              __html: catchphrase?.data?.body?.childMarkdownRemark?.html,
            }}
          />
        </div>
      </header>

      <div css={ctasCss}>
        {ctas.map(cta => (
          <CtaBlock
            id={`page-header-cta-${cta.title}`}
            key={`page-header-cta-${cta.title}`}
            Title={
              <Heading as="h2" css={ctaTitleCss}>
                {cta.title}
              </Heading>
            }
            Product={
              <Heading
                as="h3"
                css={
                  variantCssByComponentName[cta.variant] ||
                  variantCssByComponentName.PageHeaderCtaPrimary
                }
              >
                {cta.subtitle}
              </Heading>
            }
            Content={
              <div
                id={`page-header-cta-${cta.title}-content`}
                css={ctaContentCss}
                dangerouslySetInnerHTML={{ __html: cta.content }}
              />
            }
            Action={
              <LinkButton
                rightIcon={<MdArrowForward />}
                css={
                  buttonCssByComponentName[cta.variant] ||
                  buttonCssByComponentName.primaryButtonCss
                }
                to={cta.link}
                size="XL"
              >
                {cta.linkText}
              </LinkButton>
            }
          />
        ))}
      </div>
    </div>
  )
}

export default PageHeader
