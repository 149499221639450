import React from "react"
import { contentPositionerCss } from "../../../shared/styles"
import Logos from "./Logos"
import Markdown from "components/CustomPageLayout/components/Markdown"
import Heading from "components/CustomPageLayout/components/Heading"
import { normalizeData } from "../../../CustomPageLayout"

const rootCss = theme => [
  contentPositionerCss({ theme }),
  {
    textAlign: `center`,
  },
]

const headingCss = theme => ({
  color: theme.colors.grey[90],
  fontSize: theme.fontSizes[3],
  fontWeight: theme.fontWeights.body,
  margin: 0,
})

const noteCss = theme => ({
  fontSize: theme.fontSizes[0],
  colors: theme.colors.grey[50],
  margin: 0,

  a: {
    fontWeight: theme.fontWeights.semiBold,
  },
})

export function UsedByLogos({ data = {} }) {
  const {
    Heading: headerContent,
    Logos: logos,
    Markdown: markdown,
  } = normalizeData(data)
  return (
    <section css={rootCss}>
      <Heading
        css={headingCss}
        data={{ tag: "h2", ...headerContent }}
        variant="EMPHASIZED"
      />
      <Logos data={logos} />
      <Markdown data={markdown} css={noteCss} />
    </section>
  )
}

export default UsedByLogos
