import React from "react"
import { keyframes } from "@emotion/core"
import Img from "gatsby-image"
import getAnimationsEnabled from "../../../../utils/enable-animations"
import { useTrackVisibility } from "react-intersection-observer-hook"

const rootCss = theme => ({
  display: `flex`,
  margin: `${theme.space[9]} 0`,
  overflow: `hidden`,
  position: `relative`,

  "&:after": {
    content: `""`,
    background: `linear-gradient(to right,#fff 0,rgba(255,255,255,0.1) 10%,rgba(255,255,255,0.1) 90%,#fff 100%)`,
    position: `absolute`,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
})

const scrolling = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`

const listCss = ({ _theme, shouldEnableAnimations }) => [
  {
    display: `flex`,
    listStyle: `none`,
    margin: 0,
  },
  shouldEnableAnimations && {
    animation: `${scrolling} 60s linear infinite`,
    "div:hover > &": {
      animationPlayState: `paused`,
    },
  },
]

const itemCss = theme => ({
  display: `inline-flex`,
  margin: `0 ${theme.space[5]}`,
})

function List({ logos = [], ...rest }) {
  const [ref, { isVisible }] = useTrackVisibility()

  const shouldEnableAnimations = isVisible && getAnimationsEnabled()

  return (
    <ul
      ref={ref}
      css={theme => listCss({ theme, shouldEnableAnimations })}
      {...rest}
    >
      {logos.map(item => {
        const {
          image: { fixed },
          alternateText,
        } = item

        return (
          <li key={alternateText} css={itemCss}>
            <Img alt={alternateText} fixed={fixed} />
          </li>
        )
      })}
    </ul>
  )
}

export function Logos({ data = {} }) {
  const { items } = data
  return (
    <div css={rootCss}>
      <List logos={items} />
      {/* We repeat the list to get the horizontal inifinite scrolling effect  */}
      <List logos={items} aria-hidden="true" />
    </div>
  )
}

export default Logos
